<template>
  <div
    class="menu-position"
    @mouseenter="props.data.meta.submenu ? hoverOn() : null"
    @mouseleave="props.data.meta.submenu ? hoverOff($event) : null"
  >
    <RouterLink
      class="router-link"
      :to="{
        name: props.data.name,
      }"
      v-tooltip.right="
        !props.data.meta.submenu ? $t(props.data.meta.title) : null
      "
    >
      <IconBase :name="props.data.meta.icon" width="20" height="20" />
    </RouterLink>
    <Transition v-if="props.data.meta.submenu" name="slide-fade">
      <Card v-if="submenuHover">
        <template #header>
          <div class="text-md font-medium text-gray-900">
            {{ $t(props.data.meta.title) }}
          </div>
        </template>
        <template #content>
          <RouterLink
            @click="hoverOff($event, true)"
            v-for="route in props.data.children"
            :key="`submenu-${route.meta.title}`"
            :to="{ name: route.name }"
            class="text-sm router-sublink"
          >
            <IconBase :name="route.meta.icon" width="16" height="16" />
            {{ $t(route.meta.title) }}
          </RouterLink>
        </template>
      </Card>
    </Transition>
  </div>
</template>

<script setup>
import IconBase from "@/components/global/IconBase.vue";
import Card from "primevue/card";
import { defineProps, ref } from "vue";
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
let submenuHover = ref(false);
let submenuDebounce = ref(null);

function hoverOn() {
  submenuHover.value = true;
  if (submenuDebounce.value) {
    clearTimeout(submenuDebounce.value);
  }
}

function hoverOff(e, immediate = false) {
  submenuDebounce.value = setTimeout(
    () => {
      submenuHover.value = false;
    },
    immediate ? 0 : 150
  );
}
</script>

<style scoped lang="scss">

.menu-position {
  position: relative;
  :deep(.p-card) {
    min-width: 281px;
    z-index: 9999;
    position: absolute;
    left: calc(100% + 25px);
    top: -50px;
    //transform: translateY(-50%);
    .p-card-content {
      padding-top: 12px;
      display: flex;
      flex-direction: column;
    }
  }
}

.p-datatable {
  position: inherit;
}

a {
  &.router-link {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-600);
    border-radius: 8px;
    transition: all 100ms ease-in-out;
    margin-bottom: 4px;
    position: relative;
    &::before {
      position: absolute;
      width: 0px;
      height: 1px;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      bottom: -8px;
      background-color: var(--gray-100);
      transition: width 500ms ease-in-out;
    }
    &:hover {
      background-color: var(--gray-100);
      // margin-bottom: 8px;
      &::before {
        width: calc(100% + 5px);
      }
    }
    &.router-link-active {
      background-color: var(--blue-500);
      > svg {
        color: var(--base-white);
      }
    }
  }
  &.router-sublink {
    display: flex;
    color: var(--gray-700);
    font-weight: 500;
    padding: 8px 12px;
    margin-top: 4px;
    border-radius: 6px;
    svg {
      margin-right: 12px;
    }
    &.router-link-active {
      background-color: var(--gray-100);
      color: var(--gray-900);
    }
  }
}
</style>
