export default {
  form: {
    title: {
      information: "Informacje",
    },
    labels: {
      company: "Szukaj firmy, dla której chcesz wygenerować vouchery",
      addToCompany: "Firma do której dodajesz vouchery",
      tag: "Tag",
      keysCount: "Ilość kodów do wygenerowania",
      keyTime: "",
    },
    hintText: {
      tag: "Podaj tag dla tej serii kodów",
    },
    buttons: {
      add: "Dodaj vouchery",
    },
  },
  list: {
    headers: {
      id: "ID",
      companyName: "Nazwa firmy",
      keys: "Ilość wygenerowanych voucherów",
    },
  },
  sublist: {
    headers: {
      id: "ID",
      tag: "Tag",
      key: "Klucz",
      keyTime: "Ważność",
      used: "Wykorzystany",
      user: "Użyte przez",
      usedAt: "Data użycia",
    },
    template: {
      left: "Vouchery dla tej firmy",
      right: {
        buttons: {
          download: "Pobierz vouchery jako plik",
          generate: "Wygeneruj nowe vouchery",
        },
      },
    },
  },
};
