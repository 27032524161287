<template>
  <Breadcrumb
    v-if="props.data && props.data.length > 0"
    :model="props.data"
    class="text-sm font-medium text-gray-300"
  >
    <template #item="{ item }">
      <RouterLink v-if="item.path" :to="item.path" class="text-gray-900">
        {{ $t(item.title) }}
      </RouterLink>
      <div v-else class="text-gray-600">
        {{ $t(item.title) }}
      </div>
    </template>
  </Breadcrumb>
</template>

<script setup>
import Breadcrumb from "primevue/breadcrumb";
import { defineProps, watch, ref } from "vue";

const props = defineProps({
  data: {
    type: Array,
    default: function () {
      return [];
    },
  },
});
const data = ref(props.data);

watch(data, (value) => {
  console.log(value);
});
</script>

<style scoped lang="scss">
:deep(.p-menuitem-separator) {
  margin: 0 10px;
}
</style>
