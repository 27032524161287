export default {
  addNew: 'Dodaj nowy',
  dialog: {
    editMaterial: 'Edytuj materiał',
    addMaterial: 'Dodaj materiał',
  },
  list: {
    headers: {
      id: 'ID',
      name: 'Nazwa',
      description: 'Opis',
      type: 'Typ',
      series: 'Seria',
      image: 'Obrazek',
      file: 'Plik',
    },
  },
  elementType: {
    template: 'Szablon',
    report: 'Raport',
    presentation: 'Prezentacja',
    workbook: 'Workbook',
    article: "Artykuł"
  },
  element: {
    form: {
      name: 'Nazwa',
      description: 'Opis',
      main: 'Wyróżniony',
      type: 'Typ',
      image: 'Zdjęcie',
      file: 'Plik',
      addAuthor: 'Dodaj autora',
      authors: 'Autorzy',
      background: 'Tło',
      image_horizontal: "Okładka pozioma",
    }
  },
  slide: {
    form: {
      name: 'Nazwa',
      description: 'Opis',
      image: 'Zdjęcie',
      file: 'Plik',
      element: 'Powiązany materiał',
      editSlide: 'Edytuj slajd',
      addSlide: 'Dodaj slajd',
    },
  },
  slides: {
    list: {
      headers: {
        id: 'ID',
        name: 'Nazwa',
        description: 'Opis',
        type: 'Typ',
        series: 'Seria',
        image: 'Obrazek',
        file: 'Plik',
        element: 'Powiązany materiał',
      },
    },
  }
};
