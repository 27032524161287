<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_901_34997)">
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#F0F0F0"
      />
      <path
        d="M5.65234 13.4775L6.52191 19.3774C7.6052 19.7794 8.77695 19.9993 10.0002 19.9993C14.2998 19.9993 17.9652 17.2855 19.3781 13.4775H5.65234Z"
        fill="black"
      />
      <path
        d="M5.65234 6.52176L6.52191 0.621914C7.6052 0.219922 8.77695 0 10.0002 0C14.2998 0 17.9652 2.71375 19.3781 6.52176H5.65234Z"
        fill="#6DA544"
      />
      <path
        d="M0.000488281 10.0001C0.000488281 14.2997 2.71428 17.9652 6.52225 19.3781V0.62207C2.71428 2.035 0.000488281 5.70043 0.000488281 10.0001Z"
        fill="#A2001D"
      />
    </g>
    <defs>
      <clipPath id="clip0_901_34997">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
