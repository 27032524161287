<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.16699 9.99997L10.2022 14.0176C10.3115 14.0722 10.3662 14.0996 10.4235 14.1103C10.4743 14.1198 10.5264 14.1198 10.5772 14.1103C10.6345 14.0996 10.6891 14.0722 10.7985 14.0176L18.8337 9.99997M2.16699 14.1666L10.2022 18.1842C10.3115 18.2389 10.3662 18.2662 10.4235 18.277C10.4743 18.2865 10.5264 18.2865 10.5772 18.277C10.6345 18.2662 10.6891 18.2389 10.7985 18.1842L18.8337 14.1666M2.16699 5.83331L10.2022 1.81571C10.3115 1.76105 10.3662 1.73372 10.4235 1.72297C10.4743 1.71344 10.5264 1.71344 10.5772 1.72297C10.6345 1.73372 10.6891 1.76105 10.7985 1.81571L18.8337 5.83331L10.7985 9.8509C10.6891 9.90556 10.6345 9.93289 10.5772 9.94365C10.5264 9.95317 10.4743 9.95317 10.4235 9.94365C10.3662 9.93289 10.3115 9.90556 10.2022 9.8509L2.16699 5.83331Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
