<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.91667 16.6665H4.66667C3.74619 16.6665 3 15.9203 3 14.9998V3.33317C3 2.4127 3.74619 1.6665 4.66667 1.6665H16.3333C17.2538 1.6665 18 2.4127 18 3.33317V14.9998C18 15.9203 17.2538 16.6665 16.3333 16.6665H15.0833M10.5 15.8332C11.8807 15.8332 13 14.7139 13 13.3332C13 11.9525 11.8807 10.8332 10.5 10.8332C9.11929 10.8332 8 11.9525 8 13.3332C8 14.7139 9.11929 15.8332 10.5 15.8332ZM10.5 15.8332L10.5179 15.833L7.85723 18.4936L5.5002 16.1366L8.01638 13.6204M10.5 15.8332L13.1607 18.4936L15.5177 16.1366L13.0015 13.6204M8 4.99984H13M6.33333 7.9165H14.6667"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
