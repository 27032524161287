<template>
  <div class="flex flex-row min-h-screen">
    <div id="drawer">
      <div id="menu">
        <IconBase name="logo" class="mb-4 mt-4" width="32" height="32" />
        <template
          v-for="(menuPosition, i) in menuPositions"
          :key="`menuPosition-${i}`"
        >
          <MenuPosition :data="menuPosition" />
        </template>
        <Avatar
          v-if="user"
          @click="toggle"
          shape="circle"
          :icon="!user.avatar_file ? 'pi pi-user' : null"
          aria-haspopup="true" aria-controls="overlay_menu"
          :image="user.avatar_file"
          class="cursor-pointer user-avatar mt-auto mb-3 mt-5"
        />
        <Menu :model="userMenu" ref="usermenu" :popup="true" />
      </div>
    </div>
    <div class="main-content">
      <div id="navbar">
        <Breadcrumbs :data="breadcrumbs" />
        <div>
          <Transition name="slide-up">
            <div
              v-if="route.meta.buttons && route.meta.buttons.length"
              id="layout-buttons"
            >
              <router-link
                v-for="(item, i) in route.meta.buttons"
                :key="`child-route-${i}`"
                :to="{ name: item.to, params: item.params }"
              >
                <Button
                  :class="[
                    'p-button-md',
                    {
                      'p-button-secondary': item.params,
                    },
                  ]"
                >
                  <IconBase :name="item.icon" class="mr-2" />
                  {{ $t(item.title) }}
                </Button>
              </router-link>
              <slot name="buttons"></slot>
            </div>
          </Transition>
        </div>
      </div>
      <div class="px-5 py-5 main-layout-wrapper">
        <RouterView v-slot="{ Component }">
          <Transition name="slide-up" :duration="100" mode="out-in">
            <component :is="Component" />
          </Transition>
        </RouterView>
      </div>
    </div>
  </div>
</template>

<script setup>
import Breadcrumbs from "@/components/global/Breadcrumbs.vue";
import MenuPosition from "@/components/global/MenuPosition.vue";
import Avatar from "primevue/avatar";
import IconBase from "@/components/global/IconBase.vue";
import Button from "primevue/button";
import Menu from "primevue/menu";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/store/auth";
import { useI18n } from "vue-i18n";
const route = useRoute();
const { t } = useI18n({});
const menuPositions = useRouter()
  .getRoutes()
  .filter((item) => item.name.includes("View") && !item.aliasOf);

const { logout, getUser } = useAuthStore();


const breadcrumbs = computed(() => route.meta.breadcrumbs);
const user = getUser;

const usermenu = ref();

const toggle = (event) => {
  usermenu.value.toggle(event);
};

const userMenu = ref([
  {
    label: t('base.auth.logout'),
    icon: "pi pi-power-off",
    command: () => logout(),
  },
]);
</script>

<style lang="scss" scoped>

.user-avatar {
  background: var(--gray-100);
  &:hover {
    background: var(--gray-200);
  }
}
#layout-buttons {
  display: flex;
  grid-gap: 24px;
}

#drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--base-white);
  border-right: 2px solid var(--gray-200);
  #menu {
    position: sticky;
    min-height: 100vh;
    width: 76px;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--base-white);
    > div {
      display: flex;
      justify-content: center;
    }
  }
}
#navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--base-white);
  padding: 0 32px;
  border-bottom: 2px solid var(--gray-200);
  min-height: 70px;
}
:deep(.p-avatar) {
  width: 36px;
  height: 36px;
}

.main-content {
  width: calc(100vw - 76px);
  overflow: auto;
}
</style>
