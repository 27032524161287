<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6668 8.33317V5.6665C16.6668 4.26637 16.6668 3.56631 16.3943 3.03153C16.1547 2.56112 15.7722 2.17867 15.3018 1.93899C14.767 1.6665 14.067 1.6665 12.6668 1.6665H7.3335C5.93336 1.6665 5.2333 1.6665 4.69852 1.93899C4.22811 2.17867 3.84566 2.56112 3.60598 3.03153C3.3335 3.56631 3.3335 4.26637 3.3335 5.6665V14.3332C3.3335 15.7333 3.3335 16.4334 3.60598 16.9681C3.84566 17.4386 4.22811 17.821 4.69852 18.0607C5.2333 18.3332 5.93336 18.3332 7.3335 18.3332H8.75016M10.8335 9.1665H6.66683M9.16683 12.4998H6.66683M13.3335 5.83317H6.66683M16.0418 14.1665V12.7082C16.0418 11.9028 15.3889 11.2498 14.5835 11.2498C13.7781 11.2498 13.1252 11.9028 13.1252 12.7082V14.1665M13.0002 17.4998H16.1668C16.6335 17.4998 16.8669 17.4998 17.0452 17.409C17.202 17.3291 17.3294 17.2016 17.4093 17.0448C17.5002 16.8666 17.5002 16.6332 17.5002 16.1665V15.4998C17.5002 15.0331 17.5002 14.7998 17.4093 14.6215C17.3294 14.4647 17.202 14.3372 17.0452 14.2573C16.8669 14.1665 16.6335 14.1665 16.1668 14.1665H13.0002C12.5335 14.1665 12.3001 14.1665 12.1218 14.2573C11.965 14.3372 11.8376 14.4647 11.7577 14.6215C11.6668 14.7998 11.6668 15.0331 11.6668 15.4998V16.1665C11.6668 16.6332 11.6668 16.8666 11.7577 17.0448C11.8376 17.2016 11.965 17.3291 12.1218 17.409C12.3001 17.4998 12.5335 17.4998 13.0002 17.4998Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
