export default {
  list: {
    headers: {
      id: "ID",
      name: "Nazwa egzaminu",
      accredible: "Grupa Accredible",
      active: "Aktywny",
      path: "Przypisana ścieżka",
      user: "Użytkownik",
      email: "Adres e-mail",
      examName: "Egzamin",
      result: "Wynik",
      company: "Firma",
      date: "Data egzaminu",
    },
    tooltips: {
      results: "Wyniki",
    },
  },
  form: {
    title: {
      information: "Informacje",
    },
    labels: {
      name: "Nazwa egzaminu",
      language: "Wybierz język",
      active: "Aktywny",
      path: "Ścieżka",
      accredible: "Grupa Accredible",
    },
    buttons: {
      add: "Dodaj egzamin",
      edit: "Zapisz zmiany",
    },
  },
};
