export default {
  list: {
    labels: {
      season: "Sezon",
      draft: 'Wersja robocza',
    },
    headers: {
      id: "ID",
      slug: "Slug",
      name: "Nazwa filmu",
      published: "Opublikowany",
      season: "Sezon",
      episode: "Epizod",
      language: "Język",
    },
    materials: 'Materiały do video',
    hasSubtitles: 'Zawiera napisy',
    isPublic: 'Opublikowany?',
    yes: 'Tak',
    no: 'Nie',
  },
  dialog: {
    addVideo: 'Dodaj video',
    editVideo: 'Edytuj video',
  },
  form: {
    messages: {
      info: 'Dodajesz wersję językową do video',
      noLangSerie: 'Bazowa seria nie posiada wersji w wybranym języku.'
    },
    step1: {
      title: 'Film',
      slug: 'Slug link ID',
      slugDescription: 'Opcjonalnie, w przypadku braku, system sam uzupłeni identyfikator na podstawie tytułu.',
      name: 'Nazwa filmu',
      file: 'Nazwa pliku',
      fileDescription: 'Opcjonalnie, aby nadpisać nazwę pliku pobranego z Vimeo.',
      vimeoId: 'Vimeo ID',
      publishWhenConverted: 'Opublikuj film jak tylko zostanie pobrany i przekonwertowany',
      language: 'Język',
    },
    step2: {
      title: 'Opisy',
      description: 'Opis odcinka',
      longDescription: 'Długi opis odcinka',
    },
    step3: {
      title: 'Pliki',
      miniature: 'Miniatura okładki',
      chooseMiniature: 'miniaturę okładki',
      captionsPL: 'Napisy PL',
      chooseCaptionsPL: 'napisów PL',
      captionsEN: 'Napisy EN',
      chooseCaptionsEN: 'napisów EN',
    },
    step4: {
      title: 'Autorzy',
      authors: 'Wybierz autorów',
      addNewAuthor: 'Dodaj nowego autora',
    },
    step5: {
      title: 'Seria',
      serie: 'Wybierz serię',
      addNewSerie: 'Dodaj nową serię',
      episode: 'Epizod',
      season: 'Sezon',
      baseSerie: 'Video będzie należeć do właśnie tworzonej serii',
    },
    step6: {
      title: 'Czego się nauczysz',
      addNew: 'Dodaj nowe',
    },
    buttons: {
      saveDraft: 'Zapisz wersję roboczą',
      setPublishDate: 'Ustaw datę publikacji',
      previousStep: 'Wróć',
      nextStep: 'Następny krok',
      publishNow: 'Dodaj video',
      save: 'Zapisz zmiany',
      refreshVideo: 'Pobierz ponownie',
    }
  },
  materials: {
    list: {
      header: 'Lista materiałów',
      title: 'Tytuł',
      videoID: 'Video ID',
      type: 'Typ',
      localization: 'Lokalizacja',
      order: 'Kolejność',
      add: 'Dodaj materiał',
    },
    form: {
      header: 'Dodaj materiał',
      headerEdit: 'Edytuj materiał',
      name: 'Nazwa',
      video: 'Film',
      order: 'Kolejność',
      type: {
        file: 'Plik',
        url: 'Adres URL',
      },
      buttons: {
        save: 'Zapisz',
        submit: 'Dodaj materiał',
        cancel: 'Anuluj i wróć do listy materiałów',
        close: 'Zamknij',
      }
    }
  },
  whatYouLearns: {
    dialog: {
      editTitle: 'Edytuj',
      addTitle: 'Dodaj nowy',
      name: 'Nazwa',
      nameEn: 'Nazwa w tłumaczeniu angielskim',
    }
  }
};
