import { defineStore } from "pinia";

export const useApplicationStore = defineStore({
  id: "applicationStore",
  state: () => ({
    toast: null,
  }),
  getters: {
    getToast: (state) => state.toast,
  },
  actions: {
    addToast(toast) {
      this.toast = toast;
      const timeout = toast.life || 3000;
      setTimeout(() => {
        this.toast = null;
      }, timeout);
    }
  }
});
