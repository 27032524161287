export default {
  list: {
    headers: {
      id: "ID",
      name: "Nazwa ścieżki",
      nameAtHome: "Nazwa ścieżki na /home",
      activeTo: "Aktywna do",
      series: "Przypisane serie",
      company: "Firma",
      language: "Wersja",
      b2c: "B2C",
      type: "Typ",
    },
    filters: {
      series: "Przypisane serie",
      company: "Firma",
      activeTo: "Data zakończenia",
      from: "od",
      to: "do",
      b2c: "B2C",
    },
  },
  form: {
    name: "Nazwa ścieżki",
    nameAtHome: "Nazwa ścieżki na /home",
    description: "Opis ścieżki",
    slug: "Slug",
    slugDescription:
      "Ustal unikalny ciąg znaków, który znajdziemy zazwyczaj po ukośniku w adresie URL podstrony.",
    slogan: "Hasło ścieżki",
    addToCompany: "Dodaj do firmy",
    optional: "opcjonalnie",
    noCompany: "Brak firmy",
    active: "Aktywna",
    changeRequest: "Wymuś zmianę",
    language: "Wybierz język",
    tags: "Tagi",
    enterToSave: "Naciśnij Enter, aby zapisać tag",
    dateFrom: "Ścieżka ważna od",
    dateTo: "Ścieżka ważna do",
    b2c: "Ścieżka dla B2C",
    certificate: "Certyfikat",
    certificateFile: "grafikę certyfikatu",
    certificateDescription: "Opis certyfikatu",
    series: "Serie",
    seriesDescription: "Najpierw wybierz język żeby wybrać serie",
    tagExists: "Taki tag został już dodany",
    addPath: "Dodaj ścieżkę",
    isNew: "Nowość",
  },
  searchPath: "Wyszukaj ścieżkę",
  normal: "Zwykła",
};
