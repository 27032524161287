<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_637_84945)">
      <path
        d="M6.66675 6.6665V4.33317C6.66675 3.39975 6.66675 2.93304 6.8484 2.57652C7.00819 2.26292 7.26316 2.00795 7.57676 1.84816C7.93328 1.6665 8.39999 1.6665 9.33341 1.6665H15.6667C16.6002 1.6665 17.0669 1.6665 17.4234 1.84816C17.737 2.00795 17.992 2.26292 18.1518 2.57652C18.3334 2.93304 18.3334 3.39975 18.3334 4.33317V10.6665C18.3334 11.5999 18.3334 12.0666 18.1518 12.4232C17.992 12.7368 17.737 12.9917 17.4234 13.1515C17.0669 13.3332 16.6002 13.3332 15.6667 13.3332H13.3334M4.33341 18.3332H10.6667C11.6002 18.3332 12.0669 18.3332 12.4234 18.1515C12.737 17.9917 12.992 17.7368 13.1518 17.4232C13.3334 17.0666 13.3334 16.5999 13.3334 15.6665V9.33317C13.3334 8.39975 13.3334 7.93304 13.1518 7.57652C12.992 7.26292 12.737 7.00795 12.4234 6.84816C12.0669 6.6665 11.6002 6.6665 10.6667 6.6665H4.33341C3.39999 6.6665 2.93328 6.6665 2.57676 6.84816C2.26316 7.00795 2.00819 7.26292 1.8484 7.57652C1.66675 7.93304 1.66675 8.39975 1.66675 9.33317V15.6665C1.66675 16.5999 1.66675 17.0666 1.8484 17.4232C2.00819 17.7368 2.26316 17.9917 2.57676 18.1515C2.93328 18.3332 3.39999 18.3332 4.33341 18.3332Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_637_84945">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
