export default {
  form: {
    headers: {
      info: "Informacje o powiadomieniu push mobile",
    },
    labels: {
      receivers: "Odbiorcy",
      title: "Tytuł",
      body: "Treść",
    },
    buttons: {
      send: "Wyślij powiadomienie",
    },
  },
};
