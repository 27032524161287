<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.93057 16.1985C5.43751 15.0042 6.62109 14.1665 8.00033 14.1665H13.0003C14.3796 14.1665 15.5631 15.0042 16.0701 16.1985M13.8337 7.9165C13.8337 9.75745 12.3413 11.2498 10.5003 11.2498C8.65938 11.2498 7.16699 9.75745 7.16699 7.9165C7.16699 6.07555 8.65938 4.58317 10.5003 4.58317C12.3413 4.58317 13.8337 6.07555 13.8337 7.9165ZM18.8337 9.99984C18.8337 14.6022 15.1027 18.3332 10.5003 18.3332C5.89795 18.3332 2.16699 14.6022 2.16699 9.99984C2.16699 5.39746 5.89795 1.6665 10.5003 1.6665C15.1027 1.6665 18.8337 5.39746 18.8337 9.99984Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
