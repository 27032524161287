<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.16699 9.99984H18.8337M2.16699 5.83317H6.33366M14.667 5.83317H18.8337M2.16699 14.1665H6.33366M14.667 14.1665H18.8337M6.33366 18.3332V1.6665M14.667 18.3332V1.6665M6.16699 18.3332H14.8337C16.2338 18.3332 16.9339 18.3332 17.4686 18.0607C17.939 17.821 18.3215 17.4386 18.5612 16.9681C18.8337 16.4334 18.8337 15.7333 18.8337 14.3332V5.6665C18.8337 4.26637 18.8337 3.56631 18.5612 3.03153C18.3215 2.56112 17.939 2.17867 17.4686 1.93899C16.9339 1.6665 16.2338 1.6665 14.8337 1.6665H6.16699C4.76686 1.6665 4.0668 1.6665 3.53202 1.93899C3.06161 2.17867 2.67916 2.56112 2.43948 3.03153C2.16699 3.56631 2.16699 4.26637 2.16699 5.6665V14.3332C2.16699 15.7333 2.16699 16.4334 2.43948 16.9681C2.67916 17.4386 3.06161 17.821 3.53202 18.0607C4.0668 18.3332 4.76686 18.3332 6.16699 18.3332Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
