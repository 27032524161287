export default {
  list: {
    headers: {
      id: "ID",
      series: "Seria",
      questions: "Ilość pytań",
      quizIsActive: "Aktywny",
      name: "Nazwa quizu",
    },
  },
  form: {
    headers: {
      info: "Informacje",
      serie: "Seria",
      questions: "Pytania",
    },
    tabs: {
      manual: "Dodaj ręcznie",
      auto: "Dodaj automatycznie",
    },
    labels: {
      quizName: "Nazwa quizu",
      language: "Wybierz język",
      quizIsActive: "Aktywny",
      search: "Szukaj po nazwie serii",
      all: "Wszystkie",
      pl: "Po polsku",
      en: "Po angielsku",
      ae: "Po arabsku",
    },
    buttons: {
      addQuestion: "Dodaj pytanie",
      addQuiz: "Dodaj quiz",
      editQuiz: "Zapisz zmiany",
    },
  },
  modal: {
    header: "Dodaj nowe pytanie",
    labels: {
      questionText: "Treść pytania",
      answer: "Odpowiedź",
      isCorrect: "Prawidłowa",
    },
    buttons: {
      addAnswer: "Dodaj odpowiedź",
      addQuestion: "Dodaj pytanie",
      saveQuestion: "Edytuj pytanie",
    },
    badges: {
      correct: "Prawidłowa odpowiedź",
    },
  },
};
