export default {
  list: {
    labels: {
      premiereDate: "Data premiery",
      language: "Język",
    },
    headers: {
      id: "ID",
      slug: "Slug",
      title: "Tytuł",
      author: 'Autor',
      releaseDate: "Data premiery",
      language: "Wersja językowa",
      status: "Status"
    },
  },
  form: {
    tabs: {
      information: {
        title: 'Informacje',
        slug: 'Announcement slug ID',
        slugDescription: 'Opcjonalnie, w przypadku braku, system sam uzupłeni identyfikator na podstawie tytułu.',
        name: 'Tytuł',
        description: 'Opis zapowiedzi',
        chooseLanguage: 'Wybierz język',
        chooseDate: 'Wybierz przewidywaną datę premiery',
        chooseAuthor: 'Wybierz autora dot. zapowiedzi',
        status: 'Status',
        chooseStatus: 'Wybierz status'
      },
      files: {
        title: 'Pliki',
        video: 'Film zapowiedzi',
        photo: 'Okładka zapowiedzi',
        choosePhoto: 'okładkę zapowiedzi web',
        choosePhotoMobile: 'okładkę zapowiedzi mobilną',
        chooseVideo: 'film zapowiedzi web',
        chooseVideoMobile: 'film zapowiedzi mobilny',
      },
    },
    submit: 'Dodaj zapowiedź',
    save: 'Zapisz'
  },
  status: {
    new: 'Nowy',
    public: 'Opublikowany',
  }
};
