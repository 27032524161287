export default {
  list: {
    headers: {
      id: "ID",
      name: "Nazwa firmy",
      accredible: "Accredible",
      manualCertificate: "Certyfikat ręczny",
      employeesCount: "Ilość pracowników",
      maxEmployees: "Limit pracowników",
      pathsCount: "Ilość ścieżek",
    },
    tooltips: {
      employeeAdd: "Dodaj pracownika",
      employeesList: "Lista pracowników",
    },
  },
  form: {
    buttons: {
      send: "Dodaj firmę",
      save: "Zapisz zmiany",
    },
    files: {
      privacyFile: "polityki prywatności",
      tosFile: "regulaminu",
    },
    headers: {
      info: "Informacje",
      settingsPaths: "Ustawienia ścieżek kariery",
      settingsExams: "Ustawienia egzaminów",
    },
    labels: {
      name: "Nazwa firmy",
      maxEmployees: "Limit pracowników",
      pathsWarning: "Ostrzeżenie o ścieżkach",
      unjoinable: "Widoczne ścieżki bez opcji dołączenia",
      isOnboarding: "Onboarding",
      souEnabled: "SOU",
      pathsPage: "Dostęp do strony ścieżek",
      isPrivacyPolicy: "Polityka prywatności",
      userPathNext:
        "Po zakończeniu ścieżki zezwalaj na automatyczne przejście do kolejnej",
      userPathNextHelp: "Jeśli została dodana.",
      tos: "Dodatkowy regulamin",
      accredible: "Accredible",
      manualCertificate: "Certyfikat ręczny",
      cohort: "Moduł Kohorta",
    },
  },
};
