<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.16699 17.5V5.83333C7.16699 5.05836 7.16699 4.67087 7.25218 4.35295C7.48335 3.49022 8.15721 2.81635 9.01995 2.58519C9.33786 2.5 9.72535 2.5 10.5003 2.5C11.2753 2.5 11.6628 2.5 11.9807 2.58519C12.8434 2.81635 13.5173 3.49022 13.7485 4.35295C13.8337 4.67087 13.8337 5.05836 13.8337 5.83333V17.5M4.83366 17.5H16.167C17.1004 17.5 17.5671 17.5 17.9236 17.3183C18.2372 17.1586 18.4922 16.9036 18.652 16.59C18.8337 16.2335 18.8337 15.7668 18.8337 14.8333V8.5C18.8337 7.56658 18.8337 7.09987 18.652 6.74335C18.4922 6.42975 18.2372 6.17478 17.9236 6.01499C17.5671 5.83333 17.1004 5.83333 16.167 5.83333H4.83366C3.90024 5.83333 3.43353 5.83333 3.07701 6.01499C2.7634 6.17478 2.50844 6.42975 2.34865 6.74335C2.16699 7.09987 2.16699 7.56658 2.16699 8.5V14.8333C2.16699 15.7668 2.16699 16.2335 2.34865 16.59C2.50844 16.9036 2.7634 17.1586 3.07701 17.3183C3.43353 17.5 3.90024 17.5 4.83366 17.5Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
