<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3333 10.0003H4.66666M4.66666 10.0003L10.5 15.8337M4.66666 10.0003L10.5 4.16699"
      stroke="currentColor"
      stroke-width="1.67"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
