import accredible from "@/assets/languages/pl/accrediblePL";
import announcements from "@/assets/languages/pl/announcementsPL";
import authors from "@/assets/languages/pl/authorsPL";
import base from "@/assets/languages/pl/basePL";
import categories from "@/assets/languages/pl/categoriesPL";
import companies from "@/assets/languages/pl/companiesPL";
import employees from "@/assets/languages/pl/employeesPL";
import exams from "@/assets/languages/pl/examsPL";
import locale from "@/assets/languages/pl/localePL";
import login from "@/assets/languages/pl/loginPL";
import options from "@/assets/languages/pl/optionsPL";
import paths from "@/assets/languages/pl/pathsPL";
import push from "@/assets/languages/pl/pushPL";
import quizzes from "@/assets/languages/pl/quizzesPL";
import series from "@/assets/languages/pl/seriesPL";
import settings from "@/assets/languages/pl/settingsPL";
import users from "@/assets/languages/pl/usersPL";
import videos from "@/assets/languages/pl/videosPL";
import vouchers from "@/assets/languages/pl/vouchersPL";
import languages from "@/assets/languages/pl/languagesPL";
import validation from "@/assets/languages/pl/validationPL";
import cohorts from "@/assets/languages/pl/cohortsPL";
import wall from "@/assets/languages/pl/wallPL";
import programs from "@/assets/languages/pl/programsPl";
import materialZone from "@/assets/languages/pl//materialZonePL";

export default {
  accredible,
  announcements,
  authors,
  base,
  categories,
  companies,
  employees,
  exams,
  locale,
  login,
  options,
  paths,
  push,
  quizzes,
  series,
  settings,
  users,
  videos,
  vouchers,
  languages,
  validation,
  cohorts,
  wall,
  programs,
  materialZone,
};
