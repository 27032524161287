export default {
  list: {
    headers: {
      slug: "Slug",
      id: "ID",
      name: "Nazwa kategorii",
      serie: "Serie",
    },
  },
  form: {
    title: {
      information: "Informacje",
      icon: "Ikona w SVG",
    },
    labels: {
      slug: "Category Slug ID",
      name: "Nazwa kategorii",
      description: "Opis kategorii",
      language: "Wybierz język",
    },
    hint: {
      slug: "Opcjonalnie, w przypadku braku, system sam uzupłeni identyfikator na podstawie tytułu.",
    },
    buttons: {
      add: "Dodaj kategorię",
      addLang: "Dodaj wersję językową",
      edit: "Zapisz zmiany",
    },
    messages: {
      info: "Dodajesz wersję językową do kategorii",
    },
  },
};
