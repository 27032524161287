import { defineStore } from "pinia";
import {
  sanctumCSRFCookie,
  getUser,
  login as loginFunction,
  logout as logoutFunction,
} from "@/API/authorization";
import { createCookie, deleteCookie } from "@/functions/cookies.js";

export const useAuthStore = defineStore({
  id: "authStore",
  state: () => ({
    user: null,
  }),
  getters: {
    getUser: (state) => state.user,
  },
  actions: {
    async getCsrfToken() {
      return await sanctumCSRFCookie();
    },
    async fetchUser() {
      const responseUser = await getUser();
      if (responseUser?.status == 200) {
        this.user = responseUser.data.data;
        createCookie("architect-logged", true);
        return this.user;
      } else if (responseUser?.status === 403) {
        window.location.replace("https://youniversity.be/");
      }
    },
    async login(payload) {
      const response = await loginFunction(payload);
      if (response.status === 200) {
        this.user = response.data.data.user;
        createCookie("architect-logged", true);
      }
      return response;
    },
    async logout() {
      const response = await logoutFunction();
      deleteCookie("architect-logged");
      this.user = null;
      window.location.replace("/login");
      return response;
    },
    clearUser() {
      this.user = null;
    },
  },
});
