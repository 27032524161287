<template>
  <div v-if="!loading">
    <Toast />
    <component :is="layout">
      <router-view />
    </component>
  </div>
  <div v-else class="loader">
    <div class="loading-indicator">Loading...</div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { useRoute } from "vue-router";
import { computed, watch, onBeforeMount, ref } from "vue";
import ArchitectLayout from "@/layouts/ArchitectLayout";
import AuthLayout from "@/layouts/AuthLayout"
import { useAuthStore } from "@/store/auth";
import { storeToRefs } from "pinia";
import { readCookie } from "@/functions/cookies.js";
import { useApplicationStore } from "@/store/application";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
const { user } = storeToRefs(useAuthStore());
const { fetchUser, getCsrfToken } = useAuthStore();

const { getToast } = storeToRefs(useApplicationStore());

let layout = computed(() => getLayout());

const loading = ref(true);

const getUser = async () => {
  const logged = readCookie("architect-logged");
  if( logged ) {
    await fetchUser();
  }
  loading.value = false;
}
const initialize = async () => {
  loading.value = true;
  await getCsrfToken();
  await getUser();
  loading.value = false;
}

const toast = useToast();

const showToast = (toast) => {
    toast.add({ severity: 'info', summary: 'Info', detail: 'Message Content', life: 3000 });
}


watch(getToast, (newValue, oldValue) => {
  let newMicrotime = newValue?.microtime;
  let oldMicrotime = oldValue?.microtime;
  if(newValue && newMicrotime != oldMicrotime) {
    toast.add(newValue);
  }
}, {deep: true});


initialize();

function getLayout() {
  const route = useRoute();
  if (route.meta.logged) {
    return ArchitectLayout;
  }
  return AuthLayout
}
</script>

<style lang="scss" scoped>
  .loader {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
</style>
