import axios from "axios";

export async function login(data) {
  return await axios.post("/login", data);
}

export async function logout() {
  await axios.post("/logout");
}

export async function sanctumCSRFCookie() {
  return await axios.get("/sanctum/csrf-cookie");
}

export async function userSessionsLogout() {
  await axios.get("/user/sessions/logout");
}

export async function register({ data }) {
  return await axios.post("/v2/register", data);
}

export async function getUser() {
  return await axios.get("users/me");
}
