export default {
  form: {
    header: "Dane modułu",
    title: "Opcjonalny tytuł",
    module: "Moduł",
    submodule: "Powiązany",
    device: 'Web/Mobile',
    is_active: 'Pokazuj na wall',
    selectmodule: 'Wybierz moduł',
    selectsubmodule: 'Wybierz powiązany',
    use_workbook_cover: "Użyj okladki workbook",
    headers: {
      info: "Ułóż kolejność sekcji na wall",
    },
    tabs: {
      web: "Web",
      mobile: "Mobile"
    },
    buttons: {
      saveData: "Zapisz zmiany",
      addData: "Dodaj pozycję",
      cancel: "Anuluj"
    }
  },
};
