<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.29584 17.5003C8.88345 18.019 9.65532 18.3337 10.5007 18.3337C11.3461 18.3337 12.1179 18.019 12.7055 17.5003M2.41227 4.85024C2.40032 3.64078 3.05238 2.51136 4.10578 1.91699M18.5858 4.85024C18.5978 3.64078 17.9457 2.51137 16.8923 1.91699M15.5007 6.66699C15.5007 5.34091 14.9739 4.06914 14.0362 3.13146C13.0985 2.19378 11.8268 1.66699 10.5007 1.66699C9.17461 1.66699 7.90284 2.19378 6.96516 3.13146C6.02748 4.06914 5.5007 5.34091 5.5007 6.66699C5.5007 9.24215 4.85109 11.0053 4.12542 12.1715C3.5133 13.1552 3.20724 13.6471 3.21847 13.7843C3.23089 13.9362 3.26308 13.9942 3.38551 14.085C3.49608 14.167 3.99452 14.167 4.99141 14.167H16.01C17.0069 14.167 17.5053 14.167 17.6159 14.085C17.7383 13.9942 17.7705 13.9362 17.7829 13.7843C17.7941 13.6471 17.4881 13.1552 16.876 12.1715C16.1503 11.0053 15.5007 9.24215 15.5007 6.66699Z"
      stroke="white"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
