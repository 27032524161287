<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3337 5.83333L10.404 3.9741C10.1365 3.439 10.0027 3.17144 9.80313 2.97597C9.62664 2.80311 9.41393 2.67164 9.18039 2.59109C8.9163 2.5 8.61716 2.5 8.0189 2.5H4.83366C3.90024 2.5 3.43353 2.5 3.07701 2.68166C2.7634 2.84144 2.50844 3.09641 2.34865 3.41002C2.16699 3.76654 2.16699 4.23325 2.16699 5.16667V5.83333M2.16699 5.83333H14.8337C16.2338 5.83333 16.9339 5.83333 17.4686 6.10582C17.939 6.3455 18.3215 6.72795 18.5612 7.19836C18.8337 7.73314 18.8337 8.4332 18.8337 9.83333V13.5C18.8337 14.9001 18.8337 15.6002 18.5612 16.135C18.3215 16.6054 17.939 16.9878 17.4686 17.2275C16.9339 17.5 16.2338 17.5 14.8337 17.5H6.16699C4.76686 17.5 4.0668 17.5 3.53202 17.2275C3.06161 16.9878 2.67916 16.6054 2.43948 16.135C2.16699 15.6002 2.16699 14.9001 2.16699 13.5V5.83333Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
