<template>
  <component ref="icon" v-if="iconComponent" :is="iconComponent.default" />
</template>

<script setup>
import { ref, defineProps, onMounted, watch } from "vue";

const icons = {
  "arrow-left": require("./icons/IconArrowLeft.vue"),
  "arrow-right": require("./icons/IconArrowRight.vue"),
  "bar-chart-square-01": require("./icons/IconBarChartSquare01.vue"),
  "bell-ringing": require("./icons/IconBellRinging.vue"),
  "bell-ringing-01": require("./icons/IconBellRinging01.vue"),
  "bezier-curve": require("./icons/IconBezierCurve.vue"),
  briefcase: require("./icons/IconBriefcase.vue"),
  building: require("./icons/IconBuilding.vue"),
  calendar: require("./icons/IconCalendar.vue"),
  "calendar-plus": require("./icons/IconCalendarPlus.vue"),
  certificate: require("./icons/IconCertificate.vue"),
  "check-circle": require("./icons/IconCheckCircle.vue"),
  "chevron-down": require("./icons/IconChevronDown.vue"),
  clipboard: require("./icons/IconClipboard.vue"),
  copy: require("./icons/IconCopy.vue"),
  delete: require("./icons/IconDelete.vue"),
  edit: require("./icons/IconEdit.vue"),
  "file-attachment": require("./icons/IconFileAttachment.vue"),
  "file-download-02": require("./icons/IconFileDownload02.vue"),
  "file-lock": require("./icons/IconFileLock.vue"),
  film: require("./icons/IconFilm.vue"),
  folder: require("./icons/IconFolder.vue"),
  home: require("./icons/IconHome.vue"),
  "layers-three": require("./icons/IconLayersThree.vue"),
  logo: require("./icons/IconLogo.vue"),
  "play-circle": require("./icons/IconPlayCircle.vue"),
  plus: require("./icons/IconPlus.vue"),
  "plus-square": require("./icons/IconPlusSquare.vue"),
  sale: require("./icons/IconSale.vue"),
  "sale-03": require("./icons/IconSale03.vue"),
  "save-02": require("./icons/IconSave02.vue"),
  search: require("./icons/IconSearch.vue"),
  "search-md": require("./icons/IconSearchMd.vue"),
  settings: require("./icons/IconSettings.vue"),
  sliders: require("./icons/IconSliders.vue"),
  "switch-vertical": require("./icons/IconSwitchVertical.vue"),
  "switch-vertical-02": require("./icons/IconSwitchVertical02.vue"),
  trash: require("./icons/IconTrash.vue"),
  "upload-cloud": require("./icons/IconUploadCloud.vue"),
  "user-circle": require("./icons/IconUserCircle.vue"),
  users: require("./icons/IconUsers.vue"),
  "users-01": require("./icons/IconUsers01.vue"),
  "users-plus": require("./icons/IconUsersPlus.vue"),
  "users-right": require("./icons/IconUsersRight.vue"),
  "x-circle": require("./icons/IconXCircle.vue"),
  "flag-pl": require("./icons/IconFlagPL.vue"),
  "flag-en": require("./icons/IconFlagEN.vue"),
  "flag-ae": require("./icons/IconFlagAE.vue"),
  "x-close": require("./icons/IconXClose.vue"),
  "video-recorder-add": require("./icons/IconVideoRecorderAdd.vue"),
  "eye": require("./icons/IconEye.vue"),
  "eye-off": require("./icons/IconEyeOff.vue"),
  "materials": require("./icons/IconMaterials.vue"),
  "cohort": require("./icons/IconCohort.vue"),
  "cohort-material-add": require("./icons/IconCohortMaterialAdd.vue",),
  "onboarding": require("./icons/IconOnboarding.vue"),
  'material-zone': require("./icons/IconMaterialZone.vue"),
};

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  width: {
    type: [Number, String],
    default: null,
  },
  height: {
    type: [Number, String],
    default: null,
  },
});

const icon = ref(null);

const iconComponent = ref(icons[props.name]);

watch(
  () => props.name,
  () => {
    iconComponent.value = icons[props.name];
  }
);

onMounted(() => {
  const svg = icon.value.$el;

  svg.classList.add("icon-base");

  if (props.width) {
    svg.setAttribute("width", props.width);
  } else if (props.height) {
    svg.removeAttribute("width");
  }
  if (props.height) {
    svg.setAttribute("height", props.height);
  } else if (props.width) {
    svg.removeAttribute("height");
  }
});
</script>
