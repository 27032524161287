<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 22.0541C12.6564 22.0541 9.94595 19.3436 9.94595 16C9.94595 12.6564 12.6564 9.94595 16 9.94595C19.3436 9.94595 22.0541 12.6564 22.0541 16V22.0541H16Z"
      fill="#0BA5EC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 32C7.16343 32 0 24.8365 0 16C0 7.16346 7.16343 0 16 0C24.8366 0 32 7.16346 32 16V32H16ZM16 4.75676C22.2095 4.75676 27.2432 9.79054 27.2432 16V27.2432H16C9.79052 27.2432 4.75676 22.2095 4.75676 16C4.75676 9.79054 9.79052 4.75676 16 4.75676Z"
      fill="#0BA5EC"
    />
  </svg>
</template>
