<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8333 17.5V15.8333C18.8333 14.2801 17.771 12.9751 16.3333 12.605M13.4166 2.7423C14.6382 3.23679 15.5 4.43443 15.5 5.83333C15.5 7.23224 14.6382 8.42988 13.4166 8.92437M14.6666 17.5C14.6666 15.9469 14.6666 15.1703 14.4129 14.5577C14.0746 13.741 13.4257 13.092 12.6089 12.7537C11.9963 12.5 11.2198 12.5 9.66663 12.5H7.16663C5.61349 12.5 4.83692 12.5 4.22435 12.7537C3.40759 13.092 2.75867 13.741 2.42036 14.5577C2.16663 15.1703 2.16663 15.9469 2.16663 17.5M11.75 5.83333C11.75 7.67428 10.2576 9.16667 8.41663 9.16667C6.57568 9.16667 5.08329 7.67428 5.08329 5.83333C5.08329 3.99238 6.57568 2.5 8.41663 2.5C10.2576 2.5 11.75 3.99238 11.75 5.83333Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
