<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.08333 2.33398V11.6673M4.08333 11.6673L1.75 9.33398M4.08333 11.6673L6.41667 9.33398M9.91667 11.6673V2.33398M9.91667 2.33398L7.58333 4.66732M9.91667 2.33398L12.25 4.66732"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
