export default {
  modal: {
    placeholders: {
      dateFrom: "Dostęp od",
      dateTo: "Dostęp do",
    },
  },
  list: {
    headers: {
      id: "ID",
      name: "Imię i nazwisko",
      email: "Adres e-mail",
      date: "Data nadania dostępu",
      dateArchitect: "Dostęp B2B [Architekt]",
      dateSubscription: "Dostęp B2C",
      role: "Rola",
      company: "Firma",
      onboarding: "Onboarding",
    },
    labels: {
      role: "Rola",
      company: "Firma",
      hasAccess: "Dostęp",
      date: "Data dostępu",
      path: "Ścieżka",
      onboarding: "Onboarding",
      onboardingActive: 'Onboarding aktywny',
      onboardingInactive: 'Onboarding nieaktywny',
    },
  },
  form: {
    messages: {
      info: "Dodajesz użytkowników do firmy",
    },
    tabs: {
      addWithEmail: "Dodaj z adresami e-mail",
      addFormFile: "Dodaj z pliku",
      overwriteFormFile: "Nadpisz z pliku",
      deleteAccesses: "Usuń dostępy",
      automatic: "Automatycznie",
    },
    labels: {
      password: "Hasło",
      passwordHelp: "Pozostaw to pole puste, jeśli nie chcesz zmieniać",
      canPreview: "Ma podgląd",
      role: "Rola",
      enterEmailAddresses: "Adres e-mail",
      prefix: "Prefix",
      randomPass: "Losowe hasła",
      ownPass: "Własne hasło",
      name: "Imię",
      surname: "Nazwisko",
      email: "Adres e-mail",
      freeAccess: "Darmowy dostęp",
      uploadDrop: "lub przeciągnij i upuść tutaj",
      allowFormat: "Dozwolony format CSV",
      addToCompany: "Dodaj do firmy",
      optionalOption: "(opcjonalnie)",
      addWithPath: "Dodaj ze ścieżką",
      path: "Ścieżka",
      accountNumber: "Ilość kont",
      addNameAndSurname: "Dodaj imię i nazwisko",
      option: "opcjonalnie",
    },
    hintText: {
      underTextAreaAuto:
        'Np. podanie "user", poskutkuje wygenerowaniem kont z adresem',
      lifetimeAccess:
        "Jeśli nie zostanie wybrana żadna data, oznacza to dostęp dożywotni.",
      accountsQuantity: "Podaj ilość kont, które mają zostać wygenerowane.",
    },
    placeholders: {
      noPath: "Brak ścieżki",
      noCompany: "Brak firmy",
      name: "Jan",
      surname: "Kowalski",
      uploadClick: "Kliknij, aby wybrać plik",
      dateFrom: "Wybierz od kiedy",
      dateTo: "Wybierz do kiedy",
    },
    buttons: {
      addUsers: "Dodaj użytkowników",
      updateUsers: "Zaktualizuj użytkowników",
      addAnotherUser: "Dodaj kolejnego użytkownika",
    },
  },
  buttons: {
    addUsers: "Dodaj użytkowników",
    addAnotherUser: "Dodaj kolejnego użytkownika",
  },
};
