export default {
  form: {
    title: {
      loginToArchitect: "Zaloguj się do panelu architekta",
    },
    labels: {
      email: "E-mail",
      password: "Hasło",
      rememberMe: "Zapamiętaj mnie",
    },
    buttons: {
      login: "Zaloguj się",
      goToYou: "Przejdź do Youniversity",
    },
  },
};
