export default {
  list: {
    labels: {
      season: "Sezon",
      language: "Język",
      languageVersion: "Zawiera wersję językową",
      languages: {
        en: "Angielski",
        pl: "Polski",
        ae: "Arabski",
      },
      draft: "Wersja robocza",
      publishAt: "Zaplanowano datę publikacji",
      live: "Sesja live",
    },
    headers: {
      id: "ID",
      slug: "Slug",
      name: "Nazwa serii",
      published: "Opublikowany",
      season: "Sezon",
      language: "Język",
    },
  },
  dialog: {
    editSerie: "Edytuj serię",
    addSerie: "Dodaj nową serię",
    warning: "Uwaga!",
    videoWarning: "Ta seria nie ma dodanego żadnego video.",
  },
  form: {
    messages: {
      info: "Dodajesz wersję językową do serii",
    },
    step1: {
      title: "Seria",
      slug: "Slug link ID",
      slugDescription:
        "Opcjonalnie, w przypadku braku, system sam uzupłeni identyfikator na podstawie tytułu.",
      name: "Nazwa serii",
      childFor: "Wybierz serię, do której należy",
      parentSwitch: "Wersja językowa innej serii",
      parent: "Wybierz serię, do której tworzysz wersję językową",
      parentSubtitle: "(jeśli nie jest to pierwszy sezon)",
      language: "Wybierz język",
      isInspiration: "Jest inspiracją",
      oneVideo: "Tylko jedno video",
      recommended: "Polecana seria",
      season: "Sezon",
      live: "Sesja live",
      liveFrom: "Data rozpoczęcia",
      liveTo: "Data zakończenia",
      liveUrl: "Adres url",
      liveGoogle: "Link kalendarza Google",
      liveOutlook: "Link kalendarza Outlook",
    },
    step2: {
      title: "Opisy",
      description: "Opis serii",
      longDescription: "Długi opis serii",
    },
    step3: {
      title: "Pliki",
      cover: "Okładka serii",
      selectCover: "okładki serii",
      bigCover: "Duża okładka",
      selectBigCover: "dużą okładkę poziomą",
      selectVerticalCover: "okładkę pionową",
      horizontal: "Pozioma",
      vertical: "Pionowa",
      verticalCover: "Pionowa okładka",
      coverHorizontal: "Pozioma okładka",
      videoTrailer: "Video trailer",
      videoTrailerChoose: "video trailer serii",
      videoTrailerSubtitles: "Napisy do trailera",
      videoTrailerSubtitlesChoose: "napisów do trailera",
      videoTrailerSubtitlesEn: "Napisy angielskie do trailera",
      videoTrailerSubtitlesEnChoose: "napisów angielskich do trailera",
      videoSample: "Sample video",
      videoSampleChoose: "sampla video",
      videoSampleSubtitles: "Napisy do sampla",
      videoSampleSubtitlesChoose: "napisów do sampla",
      videoSampleSubtitlesEn: "Napisy angielskie do sampla",
      videoSampleSubtitlesEnChoose: "napisów angielskich do sampla",
      selectBigVerticalCover: "dużą okładkę pionową",
      selectBigCoverWeb: "dużą okładkę web",
      selectBigCoverMobile: "dużą okładkę mobile",
      workbook_vertical: "Workbook okładka pionowa",
      workbook_horizontal: "Workbook okładka pozioma",
    },
    step4: {
      title: "Ebook",
      ebook: "Wybierz ebook",
      selectEbook: "ebooka",
      name: "Nazwa",
      description: "Opis",
      ebookSwitch: "Dodaj ebooka",
    },
    step5: {
      title: "Autorzy",
      authors: "Wybierz autorów",
      addNewAuthor: "Dodaj nowego autora",
    },
    step6: {
      title: 'Filmy',
      videos: 'Wybierz video',
      addNewVideo: 'Dodaj nowe video',
    },
    step7: {
      title: 'Kategorie',
      categories: 'Wybierz kategorie',
      addNewCategory: 'Dodaj nową kategorię',
    },
    step8: {
      title: 'Jakie problemy rozwiążesz',
      problems: 'Jakie problemy rozwiążesz',
      addNew: 'Dodaj nowy',
    },
    buttons: {
      saveDraft: 'Zapisz wersję roboczą',
      setPublishDate: 'Ustaw datę publikacji',
      previousStep: 'Wróć',
      nextStep: 'Następny krok',
      publishNow: 'Opublikuj teraz',
      save: 'Zapisz',
      acceptPreview: 'Zapisz i zatwierdź'
    }
  },
  whatProblemsSolve: {
    dialog: {
      editTitle: 'Edytuj',
      addTitle: 'Dodaj nowy',
      name: 'Nazwa'
    }
  }
};
