export default {
  dialog: {
    addAuthor: "Dodaj nowego autora",
    editAuthor: "Edytuj autora",
  },
  list: {
    labels: {
      hasPhoto: "Zawiera zdjęcie",
      withoutPhoto: "Nie zawiera zdjęcia",
    },
    headers: {
      id: "ID",
      slug: "Slug",
      firstname: "Imię",
      lastname: "Nazwisko",
      photo: "Zdjęcie",
    },
  },
  form: {
    tabs: {
      information: {
        title: "Informacje",
        slug: "Author slug ID",
        slugDescription:
          "Opcjonalnie, w przypadku braku, system sam uzupłeni identyfikator na podstawie tytułu.",
        firstname: "Imię",
        lastname: "Nazwisko",
        position: "Stanowisko",
        facebookUrl: "Facebook URL",
        linkedinUrl: "Linkedin URL",
        twitterUrl: "Twitter URL",
        bio: "Bio autora",
        bioLong: "Długie bio autora",
        marketingDescription: "Opis marketingowy",
        description: "Opis",
        details: "Szczegóły",
      },
      files: {
        title: "Pliki",
        photo: "Zdjęcie",
        photoChoose: "Kliknij, aby wybrać zdjęcie",
        bio: "Zdjęcie do BIO",
        bioChoose: "Kliknij, aby wybrać zdjęcie do BIO",
        widePhoto: "Szerokie zdjęcie",
        wideChoose: "Kliknij, aby wybrać szerokie zdjęcie",
      },
    },
    submit: "Dodaj autora",
    save: "Zapisz zmiany",
  },
};
