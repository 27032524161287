export default {
  list: {
    labels: {
      company: "Firma",
      location: "Lokalizacja",
    },
    headers: {
      id: "ID",
      moduleName: "Nazwa modułu",
      companies: "Firmy",
      active: "Aktywny",
      materialName: "Nazwa materiału",
      fileName: "Nazwa pliku / link",
      createdBy: "Dodane przez",
      description: "Opis",
      materials: "Materiały",
      answers: "Odpowiedzi",
    },
    addMaterial: "Dodaj materiał",
    usersMaterialsList: "Materiały przesłane przez użytkowników",
  },
  module: {
    form: {
      title: "Informacje",
      name: "Nazwa modułu",
      company: "Dodaj do firmy",
      active: "Aktywny",
      addModule: "Dodaj moduł",
      editModule: "Zapisz moduł",
    }
  },
  material: {
    form: {
      title: "Informacje",
      name: "Nazwa materiału",
      description: "Opis",
      company: "Firma",
      companies: "Firmy",
      file: "Plik",
      url: "Adres URL",
      addMaterial: "Dodaj materiał",
      editMaterial: "Zapisz zmiany",
    },
    list: {
      name: "Nazwa",
      description: "Opis",
      user: "Użytkownik",
      file: "Plik",
      module: "Moduł",
    }
  }
};
