<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5003 18.3332C15.1027 18.3332 18.8337 14.6022 18.8337 9.99984C18.8337 5.39746 15.1027 1.6665 10.5003 1.6665C5.89795 1.6665 2.16699 5.39746 2.16699 9.99984C2.16699 14.6022 5.89795 18.3332 10.5003 18.3332Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.41699 7.47095C8.41699 7.07321 8.41699 6.87434 8.50011 6.76332C8.57254 6.66657 8.68342 6.60603 8.80397 6.59742C8.94231 6.58754 9.1096 6.69508 9.44416 6.91016L13.378 9.43905C13.6683 9.62569 13.8135 9.719 13.8636 9.83766C13.9074 9.94134 13.9074 10.0583 13.8636 10.162C13.8135 10.2807 13.6683 10.374 13.378 10.5606L9.44416 13.0895C9.1096 13.3046 8.94231 13.4121 8.80397 13.4023C8.68342 13.3936 8.57254 13.3331 8.50011 13.2364C8.41699 13.1253 8.41699 12.9265 8.41699 12.5287V7.47095Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
