<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_914_33431)">
      <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#F0F0F0"/>
      <path d="M2.06742 3.91113C1.28191 4.93312 0.689609 6.11105 0.344727 7.3907H5.54699L2.06742 3.91113Z" fill="#0052B4"/>
      <path d="M19.6558 7.39066C19.3109 6.11105 18.7186 4.93313 17.9331 3.91113L14.4536 7.39066H19.6558Z" fill="#0052B4"/>
      <path d="M0.344727 12.6084C0.689649 13.888 1.28195 15.0659 2.06742 16.0879L5.54688 12.6084H0.344727Z" fill="#0052B4"/>
      <path d="M16.0879 2.06649C15.0659 1.28098 13.888 0.688672 12.6084 0.34375V5.54598L16.0879 2.06649Z" fill="#0052B4"/>
      <path d="M3.91162 17.9316C4.93361 18.7171 6.11155 19.3094 7.39116 19.6543V14.4521L3.91162 17.9316Z" fill="#0052B4"/>
      <path d="M7.39111 0.34375C6.1115 0.688672 4.93357 1.28098 3.91162 2.06644L7.39111 5.54593V0.34375Z" fill="#0052B4"/>
      <path d="M12.6084 19.6543C13.888 19.3094 15.0659 18.7171 16.0879 17.9316L12.6084 14.4521V19.6543Z" fill="#0052B4"/>
      <path d="M14.4536 12.6084L17.9331 16.0879C18.7186 15.066 19.3109 13.888 19.6558 12.6084H14.4536Z" fill="#0052B4"/>
      <path d="M19.9154 8.69566H11.3044H11.3044V0.0846484C10.8774 0.0290625 10.4421 0 10 0C9.55785 0 9.12262 0.0290625 8.69566 0.0846484V8.69559V8.69563H0.0846484C0.0290625 9.12262 0 9.55793 0 10C0 10.4421 0.0290625 10.8774 0.0846484 11.3043H8.69559H8.69563V19.9154C9.12262 19.9709 9.55785 20 10 20C10.4421 20 10.8774 19.971 11.3043 19.9154V11.3044V11.3044H19.9154C19.9709 10.8774 20 10.4421 20 10C20 9.55793 19.9709 9.12262 19.9154 8.69566Z" fill="#D80027"/>
      <path d="M12.6089 12.6094L17.0712 17.0718C17.2765 16.8666 17.4722 16.6521 17.659 16.4298L13.8387 12.6094H12.6089V12.6094Z" fill="#D80027"/>
      <path d="M7.39109 12.6094H7.39102L2.92871 17.0717C3.13387 17.2769 3.34836 17.4727 3.5707 17.6595L7.39109 13.839V12.6094Z" fill="#D80027"/>
      <path d="M7.39098 7.39117V7.39109L2.92863 2.92871C2.7234 3.13387 2.52762 3.34836 2.34082 3.5707L6.16125 7.39113H7.39098V7.39117Z" fill="#D80027"/>
      <path d="M12.6089 7.39199L17.0713 2.92957C16.8661 2.72434 16.6516 2.52855 16.4293 2.3418L12.6089 6.16223V7.39199V7.39199Z" fill="#D80027"/>
    </g>
    <defs>
      <clipPath id="clip0_914_33431">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
