<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8333 9.16667H14.8333C15.7667 9.16667 16.2335 9.16667 16.59 9.34832C16.9036 9.50811 17.1586 9.76308 17.3183 10.0767C17.5 10.4332 17.5 10.8999 17.5 11.8333V17.5M2.49999 17.5V5.16667C2.49999 4.23325 2.49999 3.76654 2.68165 3.41002C2.84144 3.09641 3.09641 2.84144 3.41001 2.68166C3.76653 2.5 4.23324 2.5 5.16666 2.5H8.16666C9.10008 2.5 9.56679 2.5 9.92331 2.68166C10.2369 2.84144 10.4919 3.09641 10.6517 3.41002C10.8333 3.76654 10.8333 4.23325 10.8333 5.16667V17.5M1.66666 17.5H18.3333M7.91666 5.83333H5.41666M7.91666 9.16667H5.41666M7.91666 12.5H5.41666"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
