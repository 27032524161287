import accredible from "@/assets/languages/en/accredibleEN";
import announcements from "@/assets/languages/en/announcementsEN";
import authors from "@/assets/languages/en/authorsEN";
import base from "@/assets/languages/en/baseEN";
import categories from "@/assets/languages/en/categoriesEN";
import companies from "@/assets/languages/en/companiesEN";
import exams from "@/assets/languages/en/examsEN";
import paths from "@/assets/languages/en/pathsEN";
import push from "@/assets/languages/en/pushEN";
import quizes from "@/assets/languages/en/quizesEN";
import series from "@/assets/languages/en/seriesEN";
import settings from "@/assets/languages/en/settingsEN";
import users from "@/assets/languages/en/usersEN";
import videos from "@/assets/languages/en/videosEN";
import vouchers from "@/assets/languages/en/vouchersEN";
import materialZone from "@/assets/languages/en/materialZoneEN";

export default {
  accredible,
  announcements,
  authors,
  base,
  categories,
  companies,
  exams,
  paths,
  push,
  quizes,
  series,
  settings,
  users,
  videos,
  vouchers,
  materialZone,
};
