export default {
  publish: "Opublikuj",
  unpublish: "Nie publikuj",
  addLangVersion: "Dodaj wersję językową",
  edit: "Edytuj",
  remove: "Usuń",
  startsWith: "Starts with",
  contains: "Contains",
  notContains: "Not contains",
  endsWith: "Ends with",
  equals: "Equals",
  notEquals: "Not equals",
  noFilter: "No Filter",
  lt: "Less than",
  lte: "Less than or equal to",
  gt: "Greater than",
  gte: "Greater than or equal to",
  dateIs: "Date is",
  dateIsNot: "Date is not",
  dateBefore: "Date is before",
  dateAfter: "Date is after",
  clear: "Clear",
  apply: "Apply",
  matchAll: "Match All",
  matchAny: "Match Any",
  addRule: "Add Rule",
  removeRule: "Remove Rule",
  accept: "Tak",
  reject: "Nie",
  choose: "Choose",
  upload: "Upload",
  cancel: "Anuluj",
  completed: "Completed",
  pending: "Pending",
  dayNames: [
    "Niedziela",
    "Poniedziałek",
    "Wtorek",
    "Środa",
    "Czwartek",
    "Piątek",
    "Sobota",
  ],
  dayNamesShort: ["Ndz", "Pon", "Wt", "Śr", "Czw", "Pt", "Sb"],
  dayNamesMin: ["Ndz", "Pon", "Wt", "Śr", "Czw", "Pt", "Sb"],
  monthNames: [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ],
  monthNamesShort: [
    "Sty",
    "Lut",
    "Mar",
    "KWi",
    "Maj",
    "Cze",
    "Lip",
    "Sie",
    "Wrz",
    "Paź",
    "Lis",
    "Gru",
  ],
  chooseYear: "Choose Year",
  chooseMonth: "Choose Month",
  chooseDate: "Choose Date",
  prevDecade: "Previous Decade",
  nextDecade: "Next Decade",
  prevYear: "Previous Year",
  nextYear: "Next Year",
  prevMonth: "Previous Month",
  nextMonth: "Next Month",
  prevHour: "Previous Hour",
  nextHour: "Next Hour",
  prevMinute: "Previous Minute",
  nextMinute: "Next Minute",
  prevSecond: "Previous Second",
  nextSecond: "Next Second",
  am: "am",
  pm: "pm",
  today: "Today",
  weekHeader: "Wk",
  firstDayOfWeek: 0,
  dateFormat: "mm/dd/yy",
  weak: "Weak",
  medium: "Medium",
  strong: "Strong",
  passwordPrompt: "Enter a password",
  emptyFilterMessage: "No results found", // @deprecated Use 'emptySearchMessage' option instead.
  searchMessage: "{0} results are available",
  selectionMessage: "{0} items selected",
  emptySelectionMessage: "No selected item",
  emptySearchMessage: "Nie znaleziono wyników",
  emptyMessage: "Brak opcji",
  aria: {
    trueLabel: "True",
    falseLabel: "False",
    nullLabel: "Not Selected",
    star: "1 star",
    stars: "{star} stars",
    selectAll: "All items selected",
    unselectAll: "All items unselected",
    close: "Close",
    previous: "Previous",
    next: "Next",
    navigation: "Navigation",
    scrollTop: "Scroll Top",
    moveTop: "Move Top",
    moveUp: "Move Up",
    moveDown: "Move Down",
    moveBottom: "Move Bottom",
    moveToTarget: "Move to Target",
    moveToSource: "Move to Source",
    moveAllToTarget: "Move All to Target",
    moveAllToSource: "Move All to Source",
    pageLabel: "{page}",
    firstPageLabel: "First Page",
    lastPageLabel: "Last Page",
    nextPageLabel: "Next Page",
    prevPageLabel: "Previous Page",
    rowsPerPageLabel: "Rows per page",
    jumpToPageDropdownLabel: "Jump to Page Dropdown",
    jumpToPageInputLabel: "Jump to Page Input",
    selectRow: "Row Selected",
    unselectRow: "Row Unselected",
    expandRow: "Row Expanded",
    collapseRow: "Row Collapsed",
    showFilterMenu: "Show Filter Menu",
    hideFilterMenu: "Hide Filter Menu",
    filterOperator: "Filter Operator",
    filterConstraint: "Filter Constraint",
    editRow: "Row Edit",
    saveEdit: "Zapisz zmiany",
    cancelEdit: "Cancel Edit",
    listView: "List View",
    gridView: "Grid View",
    slide: "Slide",
    slideNumber: "{slideNumber}",
    zoomImage: "Zoom Image",
    zoomIn: "Zoom In",
    zoomOut: "Zoom Out",
    rotateRight: "Rotate Right",
    rotateLeft: "Rotate Left",
  },
  yes: 'Tak',
  no: 'Nie',
};
