<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_568_69766)">
      <path
        d="M7.33344 2.66617H4.53344C3.41333 2.66617 2.85328 2.66617 2.42545 2.88415C2.04913 3.0759 1.74317 3.38186 1.55142 3.75819C1.33344 4.18601 1.33344 4.74606 1.33344 5.86617V11.4662C1.33344 12.5863 1.33344 13.1463 1.55142 13.5741C1.74317 13.9505 2.04913 14.2564 2.42545 14.4482C2.85328 14.6662 3.41333 14.6662 4.53343 14.6662H10.1334C11.2535 14.6662 11.8136 14.6662 12.2414 14.4482C12.6177 14.2564 12.9237 13.9505 13.1154 13.5741C13.3334 13.1463 13.3334 12.5863 13.3334 11.4662V8.66617M5.33342 10.6662H6.44978C6.7759 10.6662 6.93896 10.6662 7.09241 10.6293C7.22846 10.5967 7.35852 10.5428 7.47782 10.4697C7.61237 10.3872 7.72767 10.2719 7.95827 10.0413L14.3334 3.66617C14.8857 3.11388 14.8857 2.21845 14.3334 1.66617C13.7812 1.11388 12.8857 1.11388 12.3334 1.66617L5.95826 8.04133C5.72766 8.27193 5.61235 8.38723 5.5299 8.52179C5.45679 8.64108 5.40292 8.77114 5.37026 8.90719C5.33342 9.06064 5.33342 9.2237 5.33342 9.54982V10.6662Z"
        stroke="currentColor"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_568_69766">
        <rect width="16" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconEdit",
};
</script>

<style scoped></style>
