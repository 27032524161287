export default {
  list: {
    headers: {
      id: "ID",
      name: "Nazwa",
      description: "Opis",
      uid: "UID",
    },
  },
  form: {
    title: {
      information: "Informacje",
    },
    labels: {
      name: "Nazwa",
      description: "Opis",
      uid: "UID",
    },
    buttons: {
      addAccredible: "Dodaj accredible",
      updateAccredible: "Zapisz zmiany",
    },
  },
  buttons: {
    addAccredible: "Dodaj accredible",
  },
};
