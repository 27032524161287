export default {
  list: {
    headers: {
      id: "ID",
      name: "Imię i nazwisko",
      jobPosition: "Stanowisko",
      team: "Zespół",
      email: "E-mail",
    },
    tooltips: {
      remove: "Usuń z firmy",
    },
  },
};
