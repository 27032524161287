<template>
  <div class="auth-layout">
    <RouterView v-slot="{ Component }">
      <Transition name="slide-up" :duration="100" mode="out-in">
        <component :is="Component" />
      </Transition>
    </RouterView>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.auth-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}
</style>
