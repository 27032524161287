import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia, storeToRefs } from "pinia";
import PrimeVue from "primevue/config";
import i18n from "@/assets/languages/languages";
import "@/assets/scss/styles.scss";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import Tooltip from "primevue/tooltip";
import ToastService from "primevue/toastservice";
import localePL from "@/assets/languages/pl/localePL";
import { useAuthStore } from "@/store/auth";
import { axios } from "/plugins/axios";
import { readCookie } from "@/functions/cookies.js";
import VueAwesomePaginate from "vue-awesome-paginate";
import { VueReCaptcha } from "vue-recaptcha-v3";

const app = createApp(App);
app.use(PrimeVue, {
  locale: localePL,
});
app.use(ToastService);

app.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    // useRecaptchaNet: true,
    autoHideBadge: true,
  },
});

const pinia = createPinia();

app.use(pinia).use(i18n).use(router).use(VueAwesomePaginate).mount("#app");

window.axios = axios;

app.directive("tooltip", Tooltip);

router.beforeEach(async (to, from, next) => {
  let breadcrumbs = [];
  let { user } = storeToRefs(useAuthStore());
  let logged = readCookie("architect-logged");

  if (!logged && to.meta.logged && !user.value) {
    next({ name: "Login" });
  }
  if (logged && !to.meta.logged) {
    next(from);
  }

  document.title = `${i18n.global.t(to.meta.title)} - Architect`;

  if (to.meta.buttons) {
    for (let buttonIndex in to.meta.buttons) {
      let button = to.meta.buttons[buttonIndex];
      if (Object.prototype.hasOwnProperty.call(button, "paramsPatterns")) {
        for (let paramKey in button.params) {
          const paramPattern = button.paramsPatterns[paramKey];
          to.meta.buttons[buttonIndex].params[paramKey] =
            to.params[paramPattern];
        }
      }
    }
  }

  to.matched.forEach((item, i) => {
    breadcrumbs.push({
      name: item.name,
      title: item.meta.title,
      path: i === 0 && item.path !== to.path ? item.path : null,
    });
    if (to.name.includes("List")) {
      to.meta.children = item.children;
    }
  });
  to.meta.breadcrumbs = breadcrumbs;
  if (to.name.includes("View") && to.meta.target) {
    next({
      name: to.meta.target,
    });
  } else {
    next();
  }
});
