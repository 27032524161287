const createCookie = function (name, value, time) {
  let expires;
  if (time) {
    const date = new Date();
    date.setTime(date.getTime() + time * 1000);
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
};

const readCookie = function (name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const deleteCookie = function (name) {
  createCookie(name, "", -1);
};

export { createCookie, readCookie, deleteCookie };
