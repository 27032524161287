<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.833 3.33317C14.608 3.33317 14.9955 3.33317 15.3134 3.41836C16.1761 3.64952 16.85 4.32339 17.0812 5.18612C17.1663 5.50404 17.1663 5.89153 17.1663 6.6665V14.3332C17.1663 15.7333 17.1663 16.4334 16.8939 16.9681C16.6542 17.4386 16.2717 17.821 15.8013 18.0607C15.2665 18.3332 14.5665 18.3332 13.1663 18.3332H7.83301C6.43288 18.3332 5.73281 18.3332 5.19803 18.0607C4.72763 17.821 4.34517 17.4386 4.10549 16.9681C3.83301 16.4334 3.83301 15.7333 3.83301 14.3332V6.6665C3.83301 5.89153 3.83301 5.50404 3.91819 5.18612C4.14936 4.32339 4.82323 3.64952 5.68596 3.41836C6.00388 3.33317 6.39136 3.33317 7.16634 3.33317M7.99967 12.4998L9.66634 14.1665L13.4163 10.4165M8.49967 4.99984H12.4997C12.9664 4.99984 13.1997 4.99984 13.378 4.90901C13.5348 4.82912 13.6623 4.70163 13.7422 4.54483C13.833 4.36657 13.833 4.13321 13.833 3.6665V2.99984C13.833 2.53313 13.833 2.29977 13.7422 2.12151C13.6623 1.96471 13.5348 1.83723 13.378 1.75733C13.1997 1.6665 12.9664 1.6665 12.4997 1.6665H8.49967C8.03296 1.6665 7.79961 1.6665 7.62135 1.75733C7.46455 1.83723 7.33706 1.96471 7.25717 2.12151C7.16634 2.29977 7.16634 2.53313 7.16634 2.99984V3.6665C7.16634 4.13321 7.16634 4.36657 7.25717 4.54483C7.33706 4.70163 7.46455 4.82912 7.62135 4.90901C7.79961 4.99984 8.03296 4.99984 8.49967 4.99984Z"
      stroke="currentColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
