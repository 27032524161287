import axios from "axios";
// import { readCookie, createCookie, deleteCookie } from "/plugins/axios";
import router from "@/router/index";
import { deleteCookie } from "@/functions/cookies.js";
import { useAuthStore } from "@/store/auth";
import { useApplicationStore } from "@/store/application";

const skipResponseUrls = ["files"];

function skipResponse(response) {
  // Is file endpoint?
  let prefix = response.config.url.split('/')[0];
  return skipResponseUrls.findIndex(url => url === prefix) !== -1;
}

axios.defaults.withCredentials = true;

axios.defaults.headers = {
  Accept: "application/json",
  Source: "web",
};

axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const { addToast } = useApplicationStore();
    if(response.config.method !== 'get' && response.config.url != '/login' && !skipResponse(response)) {
      let details = 'Pomyślnie zapisano';
      if(response.config.method == 'delete') {
        details = 'Pomyślnie usunięto';
      }
      addToast({
        severity: 'success',
        summary: 'Sukces',
        detail: details,
        life: 3000,
        microtime: Math.floor(new Date().getTime())
      });
    }
    return response;
  },
  (error) => {
    console.log("ERROR IN AXIOS", error);
    const { addToast } = useApplicationStore();
    if (error.response.status === 401) {
      const { clearUser } = useAuthStore();
      deleteCookie("architect-logged");
      clearUser();
      router.push("/login");
    }
    addToast({
      severity: 'error',
      summary: 'Błąd',
      detail: error.response?.data?.message || 'Wystąpił ',
      life: 3000,
      microtime: Math.floor(new Date().getTime())
    });
    return Promise.reject(error);
  }
);

if (process.env.NODE_ENV === "production") {
  axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api/architect`;
} else {
  axios.defaults.baseURL = `/api/architect`;
}

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return error.response;
  }
);

// axios.defaults.timeout = 30000;

export { axios };
